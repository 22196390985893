// Write your Javascript code.
const popupOpenButton = document.querySelectorAll('[data-open-popup]'),
	getAllPopups = document.querySelectorAll('.popup-overlay');

if(document.querySelector(`.landing-popup`)) {
	if (localStorage.getItem('popupFired') === '1') {
		document.querySelector('html').classList.remove('landing-popup-open');

		document.querySelector(`.landing-popup`).style.display = 'none';
	} else {
		document.querySelector(`.landing-popup`).style.opacity = '1';
		document.querySelector(`.landing-popup`).style.visibility = 'visible';
		document.querySelector(`.landing-popup`).style.zIndex = '10000';

		localStorage.setItem('popupFired', '1');
	}
}


document.addEventListener('click', (event) => {
	
	if(event.target.classList.contains('popup__close')) {
		event.preventDefault();
		
		document.querySelector('html').classList.remove('popup-open');
		
		getAllPopups.forEach(popup => {
			popup.classList.remove('show');
			popup.children[0].style.transform = 'scale(0)';
		});
	}
	
	if(event.target.classList.contains('popup__close')){
		document.querySelector(`.popup-overlay`).style.opacity = '0';
		document.querySelector(`.popup-overlay`).style.visibility = 'hidden';
		document.querySelector(`.popup-overlay`).style.zIndex = '-1';
		
		document.querySelector(`.popup`).style.transform = 'scale(0)';
		document.querySelector('html').classList.remove('popup-open');
	}
});

document.querySelectorAll('.popup-overlay').forEach(popupOverlay => {
	popupOverlay.addEventListener('click', (event) => {
		document.querySelector(`.popup-overlay`).style.opacity = '0';
		document.querySelector(`.popup-overlay`).style.visibility = 'hidden';
		document.querySelector(`.popup-overlay`).style.zIndex = '-1';
		
		popupOverlay.classList.remove('show');
		popupOverlay.firstElementChild.style.transform = 'scale(0)';
		
		document.querySelector('html').classList.remove('popup-open');
	});
});

const mobileMenuButton = document.querySelector('.header__hamburger');

if(mobileMenuButton){
mobileMenuButton.addEventListener('click', event => {
	document.querySelector('html').classList.add('mobile-menu-open');

	document.querySelector('.offcanvas').classList.add('offcanvas--open');
});
}

if(document.querySelector('.offcanvas__close')){
document.querySelector('.offcanvas__close').addEventListener('click', event => {
	document.querySelector('html').classList.remove('mobile-menu-open');

	document.querySelector('.offcanvas').classList.remove('offcanvas--open');
});
}


//megamenu dropdown - main menu click
$('.offcanvas__button').click(function(e) {
	$('.offcanvas__dropdown').slideUp();
	$(this).parent('.offcanvas__list-item').find('.offcanvas__dropdown').slideDown();
});

const offcanvasButtons = document.querySelectorAll('.offcanvas__button');

function removeClassFromElement(targetElements, className) {
	document.querySelectorAll(targetElements).forEach(element => {
		element.classList.remove(className);
	});
}

function removeHeightFromElement(targetElements) {
	document.querySelectorAll(`${targetElements}`).forEach(element => {
		element.style.maxHeight = '0';
	});
}

offcanvasButtons.forEach(button => {
	button.addEventListener('click', (event) => {
		event.preventDefault();


		if(event.target.parentElement.classList.contains('offcanvas__list-item--active')) {
			event.target.parentElement.classList.remove('offcanvas__list-item--active');
			event.target.nextElementSibling.style.maxHeight = '0';

		} else {
			removeClassFromElement('.offcanvas__list-item', 'offcanvas__list-item--active');
			removeHeightFromElement('.offcanvas__dropdown');

			event.target.parentElement.classList.add('offcanvas__list-item--active');
			event.target.nextElementSibling.style.maxHeight = '100%';
		}
	});
});


//megamenu dropdown - desktop - replacing text on number click
$('.dropdown-number-wrapper').each(function () {
	var dropdown = $(this);
	dropdown.find('.dropdown-number-holder .number').each(function (ix) {
		var listItems = dropdown.find('.dropdown-number-content-list ol li')
		
		//on dropdown number click
		$(this).on('click', function (event) {
			
			event.stopPropagation();
			//replace the text content
			dropdown.find('.dropdown-number-content p').html(listItems[ix]);
			
			dropdown.find('.dropdown-number-holder .number').each(function (selectedIx) {
				if (ix == selectedIx) {
					//select the required item
					$(this).addClass('selected');
				} else {
					$(this).removeClass('selected');
				}
				//unselect all items
				$(this).addClass('unselected');
			});
		});
	});
});

$('.view-all').click(function () {
	$(this).parent('.page__video-max-height').addClass('page__video-max-height--active');
});

// $('.header__nav-item--dropdown').hover(function () {

	// $('.header__nav-item--dropdown').Class('header__nav-item--active');
	// $('.header__nav-item--dropdown').removeClass('open');
	// $(this).addClass('header__nav-item--active');
// })

//megamenu dropdown - desktop - hiding on header mouseover
// $('.header__top').mouseover(function () {
// 	$('.header__nav-item--dropdown').removeClass('open');
// 	$('.header__nav-item--dropdown').removeClass('header__nav-item--active');
// });
//
// $('.dropdown').mouseout(function () {
// 	$('.header__nav-item--dropdown').removeClass('open');
// 	$('.header__nav-item--dropdown').removeClass('header__nav-item--active');
// });




function closeDropdownMenu() {
	if (isDropdownMenuVisible()) {
		var tmMouseLeave = new Date().getTime();

		//console.log(tmMouseOver - tmMenuShown);
		if ((tmMouseLeave - tmMenuShown) < 300) {
			//hide dropdown
			oMenu.removeClass('open');
		}
	}
}

function isDropdownMenuVisible() {
	return $('.header__nav-item--dropdown').hasClass('open');
}

//carousel.component.ts
$(".carousel").slick({
	lazyLoad: 'ondemand',
	dots: true,
	infinite: true,
	slidesToShow: 3,
	responsive: [
		{
			breakpoint: 992,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
	]
});

//EOF carousel.component.ts

//page.component.ts
$('#scroll-arrow').click(function () {
	$('html, body').animate({
		scrollTop: $("#sections-wrapper").offset().top
	}, 2000);
})
//EOF page.component.ts

//video.component.ts
$(document).on('click', '[data-toggle="lightbox"]', function (event) {
	event.preventDefault();
	$(this).ekkoLightbox();
});
//EOF video.component.ts

//nav.component.ts
$(".navbar-toggle").on("click", function () {
	$(this).toggleClass("active");
});
//EOF nav.component.ts

//section.component.js
$(".false-button").on("click", function () {
	$(this).text(function (i, text) {
		return text === "+" ? "−" : "+";
	})
});
//EOF section.component.js


$('.accordion__title').click(function(e) {
	e.preventDefault();
	
	$(this).parent('.accordion__item').toggleClass('accordion__item--active');
	$(this).parent('.accordion__item').find('.accordion__content').slideToggle('slow');
});

document.querySelectorAll('.page__play-button').forEach(button => {
	button.addEventListener('click', () => {
		let video = button.nextElementSibling

		if(video.classList.contains('playing')) {
			video.pause();
			video.classList.remove('playing')
			button.classList.remove('playing');
		} else {
			video.play();
			video.classList.add('playing')
			button.classList.add('playing');
		}
	});
});


var popupButtonOpen = document.querySelectorAll('[data-popup-open]');

document.addEventListener('click', (event) => {

	if(event.target.getAttribute('data-popup-open')) {
		let targetPopup = event.target.getAttribute('data-popup-open');

		$('.a-popup-overlay').removeClass('show');

		document.querySelector(`.a-popup-overlay[id=${targetPopup}]`).classList.add('show');
		document.querySelector(`.a-popup-overlay[id=${targetPopup}] .a-popup`).style.transform = 'scale(1)';
		document.querySelector('html').classList.add('popup-is-open');

		document.querySelector(`.a-popup-overlay[id=${targetPopup}] video`).play();
	}

	if(event.target.classList.contains('a-popup__close')) {
		event.target.parentElement.parentElement.classList.remove('show');

		event.target.parentElement.style.transform = 'scale(0)';
		document.querySelector('html').classList.remove('popup-is-open');

		document.querySelectorAll('video').forEach(video => {
			video.pause();
		});

	}
});



$('.show-scam-dropdown').on('click', function() {
	$(this).next().toggleClass('scam__accordion-content--active');
});


var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
  acc[i].addEventListener("click", function () {
	this.classList.toggle("active");
	var panel = this.nextElementSibling;
	if (panel.style.display === "block") {
	  panel.style.display = "none";
	} else {
	  panel.style.display = "block";
	}
  });
}

  // Wait for the page to load
  window.addEventListener('load', function() {
	// Get the URL hash (e.g., #videos)
	var urlHash = window.location.hash;
  
	// Check if the URL has a hash
	if (urlHash) {
	  // Extract the element ID from the hash (remove the leading '#')
	  var elementId = urlHash.substring(1);

	  // Scroll to the element
	  scrollToElement(elementId);
	}
  });
  
  
  // Function to scroll to the element with the given ID
  function scrollToElement(elementId) {
	var element = document.getElementById(elementId);
	if (element) {
	  element.scrollIntoView({ behavior: 'smooth' });
	}
  }


$('.showVideo').on('click', function() {
	$(this).next().addClass('taking-control__videos-video--show');
	// var video = $(this).next().find('iframe')[0];
	// if (video) {
	// 	var src = $(video).attr('src');
	// 	$(video).attr('src', src + '?autoplay=1');
	// }
});

$('.hideVideo').on('click', function() {
	$('.taking-control__videos-video--show').removeClass('taking-control__videos-video--show');
	$("iframe").each(function() {
		var src= $(this).attr('src');
		$(this).attr('src',src);
	});
});

// $(window).scroll(function () {
//     const scrollPosition = $(window).scrollTop();
//     $(".parallax").each(function (index) {
//         const translateY = -scrollPosition * (0.5 + 0.2 * index);
//         $(this).css("transform", "translateY(" + translateY + "px)");
//     });
// });
  

// $(document).ready(function () {
//     const parallaxElements = $(".parallax");

//     $(window).on("scroll", function () {
//         const scrollPosition = $(window).scrollTop();
//         parallaxElements.each(function (index) {
//             const translateY = -scrollPosition * (0.5 + 0.3 * index);
//             $(this).css("transform", `translateY(${translateY}px)`);
//         });
//     });
// });

  

var rellax = new Rellax('.rellax');


$('.toggle_cc_video').on('click', function() {
	var videoContainer = $('.cc__video-main');
	var videoElement = videoContainer.find('video')[0];

	videoContainer.toggleClass('cc__video-main--active');

	if (videoContainer.hasClass('cc__video-main--active')) {
		videoElement.play();
	} else {
		videoElement.pause();
	}
});


document.addEventListener("click", (event) => {
    const targetElement = event.target.closest("[data-video-title]");
    
    if (targetElement) {
        const videoTitle = targetElement.getAttribute("data-video-title");
        gtag("event", "wmm_video_click", {
            'video_title': videoTitle
        });
    }
});